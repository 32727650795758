@import '../../resources/main.scss';

.add{
    padding:40px 10%;
    background-color:  #eee;
    min-height: 100vh;

    &__container{
        display: flex;
        flex-direction: row;
        margin-top: 60px;
    }
    &__right-section{
        width:60%;
        margin-top: 40px;
    }
    &__left-section{
        width:40%;
    }
    &__form{
        display: flex;
        flex-direction: column;
        margin:40px 0 20px;
    }
    &__input{
        margin:10px 0 25px;
        height:40px;
        border:none;
        border-radius: 5px;
        padding-left: 5px;
        font-family: 'Domine', serif;
        font-size: 14px;
    }
    &__textarea{
        height:100px;
    }
    &__input::placeholder{
        font-family: 'Domine', serif;
        font-size: 14px;
        line-height: 1.47;
        color: #444444;
        font-weight: bolder;
    }
    &__button{
        background-color: #00004d;
        border:none;
        cursor: pointer;
        width:30%;
        height:40px;
        border-radius: 5px;
        margin-top: 10px;
    }
    &__button-writing{
        color:#ffff;
        font-size: 14px;
        font-weight: lighter;
    }
    &__left-section{
        display: flex;
        flex-direction: column;
        margin-right: 60px;
    }
    &__actions{
        font-size: 20px;
        font-weight: bold;
        letter-spacing: -0.63px;
        color: #010101;
        border-bottom: 1px solid grey;
        padding-bottom: 10px;
        width:80%;
    }
    &__add-button{
        width:40%;
        margin:40px 0 0;
        background-color: #00004d;
        border:none;
        border-radius: 5px;
        padding:15px 0;
        cursor: pointer;
    }
    &__indication{
        color:#ffff;
        font-size: 16px;
        font-weight: lighter;
    }
    &__input-box{
        width:100%;

        input, textarea{
            width:100%;
        }

        textarea {
          height: 100%;
        }
    }
    &__input-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    &__remove-input{
        width:16px;
        height:16px;
        position: relative;
        right:20px;
        bottom:12px;
        cursor: pointer;
    }
}
