
.attachment-form {
   &__upload-content {
     width: 90%;
   }
    &__upload-files {
      padding: 20px 5px;
      border: 1px solid lightgrey;
      cursor: pointer;
      margin:10px 0 20px;
    }

    &__existing-files {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &__file-list {
      list-style: none;
      padding: 0;
      width: 80%;
      padding-right: 10px;
      overflow-wrap: break-word;

      li {
        color: rgba(0, 0, 0, 0.4);
      }
    }

    &__remove-files {
      button {
        cursor: pointer;
      }
    }
  }

  .drop-instruction{
      display: flex;
      flex-direction: row;
      position: relative;
      margin-left: 10px;
      align-items: center;
  }
  .upload-indication{
      font-family: inherit;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.12px;
      color: rgba(0, 0, 0, 0.4);
      margin:0;
  }
  .upload-icon{
      color:#4987c2;
      padding-right: 2%;
      height: 40px;
  }
