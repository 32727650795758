
.articles{
    padding:40px 10%;
    background-color:  #eee;
    min-height: 100vh;
    &__header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    &__header-actions, &__action-article-box{
        display: flex;
        flex-direction: row;
        color: #000033;
    }
    &__action-article-box{
        cursor: pointer;
    }
    &__header-icon{
        width:18px;
        margin:0 10px;
    }
    &__action-logout{
        flex-direction: row-reverse;
        margin-left: 40px;
    }
    &__container{
        margin-top: 120px;
    }
    &__title-page{
        text-align: center;
        font-size: 26px;
        font-weight: bold;
        letter-spacing: -0.63px;
        color: #010101; 
        border-bottom: 1px solid grey;
        width:70%;
        margin:0 auto!important;
        padding-bottom: 10px;
    }
    &__wrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        margin:40px 0;
    }
    &__card{
        width:32%;
        background-color: #ffff;
        margin:20px 0;
        box-shadow: 5px 5px 10px rgba(0,0,0,0.5);
        cursor: pointer;
    }
    &__card-img{
        width:100%;
        height:250px;
        object-fit: cover;
    }
    &__card-info{
        padding:20px;
        h4, h5, p{
            text-align: left;
        }
    }
    &__preview{
        color:#1a1a1a;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding:5px 0;
        font-size: 16px;
        line-height: 1.47;
        color: #444444;
    }
    &__title{
        font-size: 18px;
        font-weight: bold;
        letter-spacing: -0.5px;
        color: #010101;
    }
    &__date{
        color:#00001a;
        padding:5px 0;
    }
    &__see-more{
        color: #2386b2;
        font-weight: bolder;
        font-size: 14px;
    }
}