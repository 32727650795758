@import '../../resources/media-queries.scss';
@import '../../resources/main.scss';

.login{
    background-color: #00004d;
    height: 100vh;
    display: flex;
    align-items: center;

      &__container{
          background-color: #ffff;
          border-radius: 10px;
          margin:auto;
          padding:40px 20px;
          height: 350px;
      }

      &__form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin:20px 0;
    }

    &__input, textarea{
        border: none;
        border-bottom: 1px solid #888;
        background-color:#ffff;
        margin:10px 0;
        padding:8px 0;
        width:100%;
        font-size: 16px;
        line-height: 1.56;
        font-weight: bold;
        color: #535f6e;
        text-indent: 5px;
        font-family: 'Domine', serif;
    }

    &__input::placeholder, textarea::placeholder{
        font-size: 16px;
        line-height: 1.56;
        color: #535f6e;
        font-family: 'Domine', serif;
    }
    &__submit-button{
        width: 100%;
        height: 40px;
        border-radius: 5px;
        border:none;
        margin:10px 0;
        background-color: #006cd4;
        cursor:pointer;
    }
    &__submit-button:hover{
        background-color: #005cb3;
    }
    &__button-writing{
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1px;
        color: #ffffff;
        padding:0 10px;
    }
    &__password-question{
        font-size: 12px;
        letter-spacing: 1px;
        color: #006cd4;
        text-align: center;
    }
    &__password-question:hover{
        color:#003566;
        cursor: pointer;
    }

    @include desktop{

        &__container{
            padding:40px;
            width: 40%;
        }
        &__submit-button{
            margin:30px 0;
            width:auto;
        }
        &__title{
            text-align: center;
            margin-bottom: 20px;
        }
        &__form{
            margin:30px 50px;
        }
        &__submit-box{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        &__password-question{
            padding:40px 0;
        }
    }
}
